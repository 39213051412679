<template>
    <div class="flex items-center justify-between">
        <h2 class="mon-h5 mon-font-bold">
            <slot>
                {{ title }}
            </slot>
        </h2>

        <UiButtonClose @click="injected?.close" />
    </div>
</template>

<script lang="ts" setup>

const {
    title,
} = defineProps<{
    title?: string
}>()

const { injected } = useBaseModalProvide()

</script>

<style lang="scss" scoped>

</style>
